import React from 'react';
import { PageProps } from 'gatsby';
import omit from 'lodash/omit';

import data from '~/data/case-studies/samsara';
import { ProjectDTO } from '~/types';
import Layout from '~/components/Layout';
import ProjectHeader from '~/components/ProjectHeader';
import ClientDescription, {
  ClientDescriptionData,
} from '~/components/ClientDescription';
import Image from '~/components/Image';
import ProjectExcerpt, {
  ProjectExcerptData,
} from '~/components/ProjectExcerpt';

import styles from './Samsara.module.scss';
import ClientPraise, { ClientPraiseData } from '~/components/ClientPraise';
import EstimationCTA from '~/components/EstimationCTA';
import OtherProjects from '../components/OtherProjects';

const Samsara: React.FC<PageProps> = ({ location }) => {
  const { generalInfo, client, scope, tldr, platforms } = data;

  const projectHeaderData: ProjectDTO = {
    ...omit(generalInfo, 'nameShort'),
    img: data.generalInfo.img,
  };

  const clientDescriptionData: ClientDescriptionData = {
    textShort: client.descriptionShort,
    textLong: client.descriptionLong,
    scope: scope.types,
    platforms: platforms,
  };

  const projectExcerptData: ProjectExcerptData = { text: tldr };

  const clientPraiseData: ClientPraiseData = {
    clientName: client.name,
    companyName: generalInfo.nameShort,
    clientPosition: client.position,
    img: client.img,
    text: client.quote,
  };
  const integrations = [scope.sfmta, scope.jordano, scope.poep, scope.mt];

  return (
    <Layout invertNavigation>
      <header className={styles.header}>
        <ProjectHeader data={projectHeaderData} />
      </header>
      <article className={styles.article}>
        <ClientDescription data={clientDescriptionData} />
      </article>
      <article className={styles.articleImg}>
        <Image
          img={data.exampleImg}
          alt={generalInfo.nameShort}
          className={styles.img}
        />
      </article>
      <article className={styles.article}>
        <ProjectExcerpt data={projectExcerptData} />
      </article>
      <article className={styles.integrationsArticle}>
        <h2 className={styles.integrationArticleTitle}>{scope.title}</h2>
        <h3 className={styles.integrationArticleSubtitle}>
          {scope.descriptionShort}
        </h3>
        {integrations.map(({ title, img, text }) => (
          <section className={styles.integrationsSection} key={title}>
            <div
              className={styles.integrationsText}
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <Image img={img} alt={title} className={styles.img} />
          </section>
        ))}
      </article>
      <article className={styles.articleXlg}>
        <ClientPraise data={clientPraiseData} />
      </article>
      <article className={styles.cta}>
        <EstimationCTA />
      </article>
      <article>
        <OtherProjects curretPageUrl={location.pathname} />
      </article>
    </Layout>
  );
};

export default Samsara;
