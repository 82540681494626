import { ProjectCategory } from '~/enums';

import samsaraMain from './assets/samsara.jpg';
import samsaraMainMobile from './assets/samsara-mobile.jpg';
import samsaraAvatar from './assets/samsara-avatar.jpg';
import samsaraExampleImg from './assets/samsara-example.jpg';
import samsaraSfmtaImg from './assets/samsara-sfmta.jpg';
import samsaraJordanoImg from './assets/samsara-jordano.jpg';
import samsaraPoepImg from './assets/samsara-poep.jpg';
import samsaraMtImg from './assets/samsara-mt.jpg';
import routes from '~/routes';

const samsaraSfmtaText =
  '<p><b>San Francisco MTA</b>, an agency that manages all transportation in San Francisco, asked SwingDev to write and integrate a script for data communication. If the data stream is stopped for any reason, transit authorities charge fees to bus companies, so our solution needed to be extremely reliable.</p><p>SFMTA has been using it for over a year, and we are still responsible for its maintenance. We designed it so that it can serve as a base for similar solutions for other transportation companies - SwingDev can now offer a similar service at a lower price and have already gained several more very satisfied customers.</p>';
const samsaraJordanoText =
  '<p><b>Jordano’s</b> is a food and beverage distribution company employing over 600 people. They asked us to integrate Samsara’s Hours of Service system with their workforce management program.</p><p>SwingDev was the second team to be entrusted with this task. The first one didn’t succeed, as the process required not only an expert understanding of the code but also experience with Samsara. After we completed this 15-day project, the satisfied client proposed to collaborate with us on another one. Jordano’s still uses the scripts SwingDev prepared for them.</p>';
const samsaraPoepText =
  '<p><b>Pin Oak Energy Partners</b> is an independent energy company, focused on the acquisition, exploration, development, and production of crude oil and natural gas assets.</p><p>SwingDev built an Android mobile app for employees working on oil rigs that uses the Samsara API. The Samsara Industrial Manual Entry App, fully developed and designed by SwingDev, serves as a data entry portal for fields to find in a Samsara Dashboard. Because it is used mainly by non-technical people in difficult environmental conditions, we aimed for a design that features large icons and simple navigation. Users can also enter data in case of a lack of cell phone range.</p>';
const samsaraMtText =
  '<p><b>Matrix Transportation</b>, the fastest-growing transportation company in the Midwest, gave us the task of writing a script that converts the GPS data collected by Samsara to the format of Axon - a popular trucking company management system. As GPS data is crucial for Matrix’s operation, they needed a script to be ready as soon as possible. With this in mind, we managed to complete the project in a week ahead of schedule.</p><p>Thanks to the experience gained in this project, we have become one of the main providers of integrations between Samsara and Axon. Since then, we have implemented several similar solutions for other clients.</p>';

const samsaraGeneralInfo = {
  name: 'Samsara Custom Integrations',
  nameShort: 'Samsara',
  category: ProjectCategory.INDUSTRIAL_IOT,
  summary: 'Bringing Samsara’s possibilities to new grounds',
  img: samsaraMain,
  imgMobile: samsaraMainMobile,
  projectPageUrl: routes.CASE_STUDIES_SAMSARA,
  description:
    'As official partners, we implement custom software integrations for crucial clients of Samsara - an industrial IoT leader valued at over 5B USD.',
};

const samsaraCaseStudy = {
  generalInfo: samsaraGeneralInfo,
  client: {
    name: 'Joe Shneider',
    position: 'Enterprise Sales Engineer',
    img: samsaraAvatar,
    quote:
      'SwingDev has been an incredibly valuable partner for Samsara and our customers. They’ve helped us close several deals where the customers’ needs extended beyond the scope of our platform. SwingDev has been able to create customized solutions using our API to meet their requirements and help us deliver a more complete solution',
    descriptionShort:
      'Samsara is a leading industrial IoT company, valued at over 5 billion USD and having over 15,000 customers.',
    descriptionLong:
      'It combines hardware and software to offer a wide variety of services, including fleet management, automation or asset tracking. Some of Samsara’s clients help from expert programmers to integrate their other technical solutions and infrastructure with Samsara’s products. That is where SwingDev comes in, as an official recommended partner for custom Samsara integrations.',
  },
  scope: {
    types: ['Custom Code Integration'],
    title: 'Our Work',
    descriptionShort:
      'Over a span of the last two years, many companies have benefited from Samsara’s products thanks to our coding skills.',
    sfmta: {
      title: 'San Francisco MTA integration',
      text: samsaraSfmtaText,
      img: samsaraSfmtaImg,
    },
    jordano: {
      title: 'Jordano integration',
      text: samsaraJordanoText,
      img: samsaraJordanoImg,
    },
    poep: {
      title: 'Pin Oak Energy Partners integration',
      text: samsaraPoepText,
      img: samsaraPoepImg,
    },
    mt: {
      title: 'Matrix Transportation integration',
      text: samsaraMtText,
      img: samsaraMtImg,
    },
  },
  exampleImg: samsaraExampleImg,
  platforms: ['Web', 'Mobile'],
  tldr:
    'SwingDev implements custom Samsara integrations for many of its crucial clients. Thanks to our expert knowledge of Samsara, we can handle every task, from writing scripts on a short schedule, to building Samsara-based mobile apps. We are also a long-term maintenance provider for all of our solutions.',
};

export default samsaraCaseStudy;
